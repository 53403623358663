import { graphql } from 'gatsby';
import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Call from '../components/Call';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const maxLength = 500;

const Contact = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;

  return (
    <Layout bodyClass="page-default-single">
      <SEO title={title} />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
            <h1 className="title">{title}</h1>
            <Call showButton={false} />
          </div>
          <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
            <MapContainer 
              className='intro-image-map'
              center={[46.1519696,19.5787726]} zoom={15} scrollWheelZoom={false} attributionControl={false} >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[46.1519696,19.5787726]}>
                <Popup>
                  Kladomox Plus
                </Popup>
              </Marker>
            </MapContainer>
          </div>
        </div>
      </div>

      <div className="strip">
        <div className="container pt-6 pb-6 pb-md-10">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div className="content mt-4" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
      <div className="strip">
        <form
          className='container col- pt-6 pb-6 pb-md-10'
          method="post"
          target="dummyframe"
          action="https://rest.kladomoxplus.com/contact">
          <label htmlFor="name" className='d-flex mb-1'>Ime</label>
          <input className='d-flex mb-1' type="text" name="name" />
          
          <label htmlFor='email' className='d-flex mb-1'>
          E-mail
          </label>
          <input className='d-flex mb-1' type="email" name="email" />
          <label htmlFor='phone' className='d-flex mb-1'>
          Broj telefona
          </label>
          <input type="text" name="phone" />
          <label htmlFor='message' className='d-flex mb-1'>
          Poruka
          </label>
          <textarea
            className='d-flex flex-column  mb-1 message-area'
            maxLength={maxLength}
            rows={10}
            name='message'
          />
          <input type="submit" value="POŠALJITE UPIT"/>
        </form>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
}
`;

export default Contact;
